<template>
  <div class="add-ticket-form container">
    <div class="row" style="margin-top: 50px;">
      <div class="col-md-12">
        <div class="card-custom">
          <form data-custom-id="ticket-create-form" @submit.prevent="addTicket" enctype="multipart/form-data">
            <div class="header-form"><h3>Create new ticket</h3></div>
            <div class="modal-body">
              <div class="panel-info">
                <div class="panel-body" style="margin-top: 10px">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group field-threadform-title">
                        <label class="control-label" data-custom-for="threadform-title">Short title</label>
                        <input type="text" data-custom-id="threadform-title" class="form-control"
                               name="ThreadForm[title]" v-model="title" required>
                        <!--                        <p class="help-block help-block-error"></p>-->
                      </div>
                      <div class="form-group field-ticketform-highlight">
                        <label class="control-label" data-custom-for="ticketform-highlight">Add Some highlights, if
                          necessary:</label>
                        <input type="text" data-custom-id="ticketform-highlight" class="form-control"
                               v-model="highLight">
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group field-threadform-body">
                        <label class="control-label" data-custom-for="threadform-body">Subject and problem
                          description</label>
                        <textarea data-custom-id="threadform-body" class="form-control" v-model="body"
                                  rows="4"></textarea>
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group field-ticketform-daterange">
                        <label class="control-label" data-custom-for="ticketform-daterange">Time for processing</label>
                        <div>
                          <flat-pickr v-model="dateRange"
                                      :config="config"
                                      class="form-control"
                                      style="background-color: transparent"
                                      placeholder="Select Date & Time"
                                      @change="setDates"></flat-pickr>
                        </div>
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>
                    <div class="col-md-4">

                      <label>Choose your apartment or </label>
                      <a @click="openModal">
                        <span class="badge warning" style="margin-left: 5px; cursor:pointer">Add new</span>
                      </a>

                      <teleport to="#modal-area">
                        <div v-if="modalOpened" class="modal-area">
                          <span @click="closeModal" class="close-modal">&times;</span>
                          <add-object :latitude="lat" :longitude="lon" :addressFromMap="addressFromMap"></add-object>
                        </div>
                      </teleport>

                      <Multiselect
                          v-model='apartmentId'
                          placeholder='Select object...'
                          label='name'
                          trackBy='name'
                          valueProp="id"
                          :searchable='true'
                          @select='fetchPrices'
                          :options='apartments'>
                      </Multiselect>
                      <p class="help-block help-block-error"></p>
                    </div>
                    <div class="col-md-4">
                      <label class="control-label" data-custom-for="ticketform-help_topic_id">Type of service
                        layout</label>
                      <Multiselect
                          v-model='helpTopicId'
                          placeholder='Select type of service...'
                          label='name'
                          trackBy='name'
                          valueProp='id'
                          :searchable='true'
                          :options='topics'
                          @select='fetchPrices'
                      >
                      </Multiselect>
                      <p class="help-block help-block-error"></p>
                    </div>
                  </div>

                  <hr/>
                  <div class="panel-info">
                    <div class="row"></div>
                    <!--                <div class="panel-heading"><h5>-->
                    <!--                  <a @click="openMapsOptions">Maps options</a>-->
                    <!--                </h5></div>-->
                    <!--                <div class="panel-body" data-custom-id="map-details" :style="addMapsOptions">-->
                    <div class="panel-body">
                      <div>
                        <div id="map"/>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group field-ticketform-longitude">
                            <label class="control-label" data-custom-for="ticketform-longitude">Longitude</label>
                            <input type="text" data-custom-id="ticketform-longitude" class="form-control" v-model="lon"
                                   @change="setLatitude">
                            <p class="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group field-ticketform-latitude">
                            <label class="control-label" data-custom-for="ticketform-latitude">Latitude</label>
                            <input type="text" data-custom-id="ticketform-latitude" class="form-control" v-model="lat"
                                   @change="setLongitude">

                            <p class="help-block help-block-error"></p>
                          </div>
                        </div>

                        <div class="col-md-8">
                          <div class="form-group field-ticketform-address">
                            <label class="control-label" data-custom-for="ticketform-address">Address</label>
                            <div class="input-group">
                              <a class="input-group-text" title="Search address on Map" @click="searchAdressOnMap">
                                <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
                              </a>
                              <input type="text" data-custom-id="ticketform-address" class="form-control"
                                     placeholder="Type address here... and click search" v-model="addressFromMap"
                                     @change="setAddress">
                            </div>
                            <p class="help-block help-block-error"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div class="panel-info">
                    <div class="row"></div>
                    <!--                <div class="panel-heading">-->
                    <!--                  <h5>-->
                    <!--                  <a @click="openAdvancedOptions" > Advanced options-->
                    <!--                      <img :src="[isAdvancedActive ? 'https://cdn0.iconfinder.com/data/icons/glyphpack/29/caret-down-512.png': 'https://cdn0.iconfinder.com/data/icons/glyphpack/29/caret-up-512.png']" width="20" height="10">-->
                    <!--                  </a></h5>-->
                    <!--                </div>-->
                    <!--                <div class="panel-body" data-custom-id="ticket-details" :style="addAdvancedOptions">-->
                    <div class="panel-body" data-custom-id="ticket-details">
                      <div class="row">
                        <div class="col-md-4">

                          <div class="form-group field-ticketform-dept_id">
                            <label class="control-label" data-custom-for="ticketform-dept_id">Department</label>
                            <Multiselect
                                v-model='departmentId'
                                placeholder='Select department...'
                                :searchable='true'
                                label='name'
                                valueProp='id'
                                trackBy='name'
                                :options='departments'
                            >
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="form-group field-ticketform-team_id">
                            <label class="control-label" data-custom-for="ticketform-team_id">Team</label>
                            <Multiselect
                                v-model='teamId'
                                mode="tags"
                                placeholder='Select team(s)...'
                                label='name'
                                trackBy="name"
                                valueProp="id"
                                :searchable='true'
                                :options='teams'

                            >
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="form-group field-ticketform-assigned_to">
                            <label class="control-label" data-custom-for="ticketform-team_id">Assigned
                              Co-Worker(s)</label>
                            <Multiselect
                                v-model='assignedTo'
                                mode='tags'
                                placeholder='Choose co-worker(s)...'
                                trackBy='name'
                                valueProp='id'
                                label='name'
                                :searchable='true'
                                :options='workers'
                            >
                              <template v-slot:tag="{ option, remove, disabled }">
                                <div class="multiselect-tag is-user">
                                  <img class="user-avatar" :src="option.avatarLink">
                                  {{ option.name }}
                                  <i
                                      v-if="!disabled"
                                      @click.prevent
                                      @mousedown.prevent.stop="remove(option)"
                                  />
                                </div>
                              </template>
                              <template v-slot:option="{ option }">
                                <img class="user-avatar" :src="option.avatarLink"> {{ option.name }}
                              </template>
                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="form-group field-ticketform-responsible_user">
                            <label class="control-label" data-custom-for="ticketform-responsible_user">Responsible
                              worker</label>
                            <Multiselect
                                v-model='responsibleUser'
                                placeholder='Choose major worker...'
                                trackBy='name'
                                valueProp='id'
                                label='name'
                                :searchable='true'
                                :options='workers'
                            >
                              <template v-slot:singlelabel="{ value }">
                                <div class="multiselect-single-label">
                                  <img class="user-avatar" :src="value.avatarLink"> {{ value.name }}
                                </div>
                              </template>

                              <template v-slot:option="{ option }">
                                <img class="user-avatar" :src="option.avatarLink"> {{ option.name }}
                              </template>
                            </Multiselect>

                            <!--                    <p class="help-block help-block-error"></p>-->
                          </div>
                        </div>

                        <div class="col-md-4">

                          <div class="row">
                            <div class="form-group field-ticketform-sell_price col-md-6">
                              <label class="control-label" data-custom-for="ticketform-sell_price">Price for Buyer,
                                HC</label>
                              <input type="text" data-custom-id="ticketform-sell_price" class="form-control"
                                     v-model="sellPrice">
                              <p class="help-block help-block-error"></p>
                            </div>
                            <div class="form-group field-ticketform-price col-md-6">
                              <label class="control-label" data-custom-for="ticketform-price">Price for Team, HC</label>
                              <input type="text" data-custom-id="ticketform-price" class="form-control" v-model="price"
                                     required>
                              <p class="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div class="form-group field-ticketform-for_whome">
                            <label class="control-label" data-custom-for="ticketform-for_whome">For Whome (for
                              invoice)</label>
                            <Multiselect
                                v-model='forWhome'
                                placeholder='Choose customer...'
                                trackBy='name'
                                valueProp='id'
                                label='name'
                                :searchable='true'
                                @select='addExternalCustomer'
                                :options='[{ name:"Guest", id:"guest",},{ name:"Owner", id:"owner",},{ name:"Internal", id:"internal",},{ name: "External", id: "external",},]'
                            >

                            </Multiselect>
                            <p class="help-block help-block-error"></p>
                          </div>

                          <div class="customer-detail" :style="addExternalCustomerForm">
                            <h5>Customer detail:</h5>
                            <div><label class="control-label" data-custom-for="ticketform-estimated_time">Name /
                              Company</label>
                              <input type="text" data-custom-id="ticketform-estimated_time" class="form-control"
                                     v-model="customerName">
                              <p class="help-block help-block-error"></p></div>
                            <div><label class="control-label" data-custom-for="ticketform-estimated_time">OIB</label>
                              <input type="text" data-custom-id="ticketform-estimated_time" class="form-control"
                                     v-model="customerOIB">
                              <p class="help-block help-block-error"></p></div>
                            <div><label class="control-label"
                                        data-custom-for="ticketform-estimated_time">Address</label>
                              <input type="text" data-custom-id="ticketform-estimated_time" class="form-control"
                                     v-model="customerAddress">
                              <p class="help-block help-block-error"></p></div>
                            <div style="padding-bottom: 10px">
                              <button type="button" class="btn btn-warning save-rent-button" @click="addCustomer"><i
                                  class="fas fa-plus"></i> Add
                              </button>
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group field-ticketform-estimated_time col-md-6">
                              <label class="control-label" data-custom-for="ticketform-estimated_time">Estimated time,
                                min</label>
                              <input type="text" data-custom-id="ticketform-estimated_time" class="form-control"
                                     v-model="estimatedTime">
                              <p class="help-block help-block-error"></p>
                            </div>

                            <div class="form-group field-ticketform-status_id col-md-6">
                              <label class="control-label" data-custom-for="ticketform-status_id">Current status</label>
                              <Multiselect
                                  v-model='statusId'
                                  placeholder='Select status...'
                                  label='name'
                                  valueProp="id"
                                  trackBy='name'
                                  :searchable='true'
                                  :options='statuses'
                              >
                              </Multiselect>
                              <p class="help-block help-block-error"></p>
                            </div>

                            <div class="form-group field-ticketform-priority_id">
                              <label class="control-label" data-custom-for="ticketform-priority_id">Priority</label>
                              <Multiselect
                                  v-model='priorityId'
                                  placeholder='Select priority...'
                                  label='name'
                                  trackBy='name'
                                  :searchable='true'
                                  :options='priorities'
                              >
                              </Multiselect>
                              <!--                        <p class="help-block help-block-error"></p>-->
                            </div>

                          </div>
                        </div>


                        <!--                    <div class="col-md-4">-->
                        <!--                      <div class="col-md-12">-->
                        <!--                        <ul>-->
                        <!--                          <li v-for="(picture, index ) in pictures" :key="index">-->
                        <!--                            <label class="control-label" data-custom-for="ticketform-address">{{picture.fileName}}</label>-->
                        <!--                            <img :src="picture.source" width="25" height="25" style="border: 1px solid #cccccc">-->
                        <!--                          </li>-->
                        <!--                        </ul>-->
                        <!--                      </div>-->

                        <div class="col-md-4">
                          <!--                      <div style="width: 100%; height: 250px;border: 1px solid #cccccc">-->
                          <!--                        <div class="card-group col-md-12">-->
                          <!--                        <div class="card" v-for="(picture, index ) in pictures" :key="index" style="width: 9rem;">-->
                          <!--                          <img :src="picture.source" class="card-img-top" alt="...">-->
                          <!--&lt;!&ndash;                          <div class="card-body">&ndash;&gt;-->
                          <!--&lt;!&ndash;                            <p class="card-text">{{picture.fileName}}</p>&ndash;&gt;-->
                          <!--&lt;!&ndash;                          </div>&ndash;&gt;-->
                          <!--                          <div class="card-footer">-->
                          <!--                            <small class="text-muted">{{picture.fileName}}</small>-->
                          <!--                          </div>-->
                          <!--                        </div>-->
                          <!--                      </div>-->
                          <!--                      </div>-->
                          <div style="width: 100%; height: 230px;border: 1px solid #cccccc">
                            <!--                        <div class="row row-cols-1 row-cols-3 g-4">-->
                            <!--                          <div class="col">-->
                            <div class="card-group col-md-12">
                              <div class="card" v-for="(picture, index ) in pictures" :key="index"
                                   style="width:9rem; margin: 2px">
                                <img :src="picture.source" class="card-img-top" alt="...">
                                <div class="card-body" style="padding: 2px">
                                  <small class="text-muted">{{ picture.fileName }}</small>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="form-group col-md-12">
                            <div class="" style="margin-top: 10px;">
                              <label class="custom-file-input btn btn-warning" for="files"/>
                              <input id="files" type="file" multiple="multiple" name="PhotosForm[files]" ref="files"
                                     accept="image/*" style="visibility: hidden;" v-on:change="handleFileUploads">
                              <!--                          <input id="files" type="file" multiple="multiple" name="_photos"  ref="files" accept="image/*" style="visibility: hidden;" v-on:change="handleFileUploads">-->
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="form-group field-ticketform-id">
                        <input type="hidden" data-custom-id="ticketform-id" class="form-control" v-model="id">
                        <p class="help-block help-block-error"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="footer-form" style="display: block">
              <div class="row">
                <div class="col-md-12">
                  <!--                  <button type="button" class="btn btn-danger" @click="" >&#10007; Cancel</button>-->
                  <input type="reset" value="&#x2704; Clear form" class="btn btn-warning" style="margin-right:10px">
                  <input type="submit" class="btn btn-primary" value="&#10011; Add new Ticket">

                  <!--                <input type="submit" class="btn btn-primary" v-if="id === null || id === undefined" value="&#10011; Add new Ticket" @click="addTicket">-->
                  <!--                <input type="submit"  class="btn btn-warning" value="&#10001; Update">-->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
// import 'bootstrap/dist/css/bootstrap.css';
import axios from "axios";
import Multiselect from '@vueform/multiselect'
import {computed, createApp, onMounted, reactive, toRefs} from "vue";
import {defineComponent, ref, nextTick} from "vue";
import {useStore} from "../store";
import {ActionTypes} from "../store/actions";
import ticketsService from "@/services/tickets.service";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapContent from "@/components/MapContent";
import AddObject from "@/components/AddObject";
import router from "@/router";
import {MutationType} from "@/store/mutations";
import chatService from "../services/chatService";


export default {
  name: "AddTicket",
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.getUser);
    const workers = computed(() => store.getters.filteredResources);
    const departments = computed(() => store.getters.departments);
    const priorities = computed(() => store.getters.prioritiesOptions);
    const topics = computed(() => store.getters.topics);
    const modalOpened = computed(() => store.getters.modalOpened);
    const choosedApartmentId = ref(-1);
    const lastAddedApartment = computed(() => store.getters.lastAddedApartment);
    const resources = computed(() => store.getters.filteredResources);
    const apartments = computed(() => store.getters.apartments);
    const owners = computed(() => store.getters.owners);
    const statuses = computed(() => store.getters.statuses);
    const teams = computed(() => store.getters.teams);
    const myTitle = ref("Unchanged Popup Title");
    const ticketId = ref("123");
    const form = reactive({
      pictures: []
    })
    const readURL = file => {
      return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.onload = e => res(e.target.result);
        reader.onerror = e => rej(e);
        reader.readAsDataURL(file);
      });
    };
    const handleFileUploads = event => {

      const fieldName = event.target.name;
      const fileList = event.target.files;
      if (!fileList.length) return;
      const formData = new FormData();
      form.pictures = [];
      fileList.forEach(async file => {
        const url = await readURL(file)
        form.pictures.push({
          source: url,
          fileName: file.name,
        })
      })
      // Array
      //     .from(Array(fileList.length).keys())
      //     .map(x => {
      //       formData.append(fieldName, fileList[x], fileList[x].name);
      //     });
      // chatService.setAvatar(formData, props.id).then((response)=> {
      //   if (response.data.success) {
      //     state.avatarLink = response.data.avatar;
      //     state.fullSizeLink = response.data.fullSizeLink;
      //   }
      // })
    }

    // const apartmentId = ref(null);
    const lat = ref(45.3148);
    const lon = ref(14.2873);
    const addressFromMap = ref("");
    const closeModal = () => {
      store.commit(MutationType.changeModal, false);
    };
    const openModal = () => {
      store.commit(MutationType.changeModal, true);
    };
    const fetchAddressWithMarker = (longitude, latitude) => {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latitude + "," + longitude + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      console.log(url)
      axios.get(url).then((response) => {
        if (response.status === 200) {
          lat.value = response.data.results[0].geometry.location.lat;
          lon.value = response.data.results[0].geometry.location.lng;
          addressFromMap.value = response.data.results[0].formatted_address;
        }
      });
    };
    const onDragEnd = (el) => {
      lat.value = el.target.getLngLat().lat;
      lon.value = el.target.getLngLat().lng;
      fetchAddressWithMarker(lon.value, lat.value);
    };
    const searchAdressOnMap = () => {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + addressFromMap.value + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      axios.get(url).then((response) => {
        if (response.status === 200) {
          lat.value = response.data.results[0].geometry.location.lat;
          lon.value = response.data.results[0].geometry.location.lng;
          addressFromMap.value = response.data.results[0].formatted_address;
        }
      });
    };
    const chooseObject = (id) => {
      const apartment = apartments.value.filter((apartment) => apartment.id === id)
      lat.value = apartment.latitude;
      lon.value = apartment.longitude;
      addressFromMap.value = apartment.address;
    }
    // const map = new mapboxgl.Map({
    //   container: "map",
    //   style: "mapbox://styles/mapbox/streets-v11",
    //   center: [lon.value,lat.value],
    //   zoom: 8
    // });
    onMounted(() => {
      store.dispatch(ActionTypes.fetchApartments, null);
      store.dispatch(ActionTypes.fetchOwners, null);
      store.dispatch(ActionTypes.fetchTeams, null);

      mapboxgl.accessToken = "pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw";

      const map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lon.value, lat.value],
        zoom: 8
      });

      map.on('load', () => {
        map.scrollZoom.enable();
        map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.ScaleControl({position: 'bottom-left'}));
        const marker = new mapboxgl.Marker({draggable: true})
            .setLngLat([14.2873, 45.3148])
            .setPopup(new mapboxgl.Popup({offset: 25})
                .setHTML('<div>Rona Central Reception</div>'))
            .addTo(map);
        marker.on('dragend', onDragEnd);

        apartments.value.forEach((apartment) => {
          const popup = new mapboxgl.Popup({offset: 25})
              // .setHTML('<div id="apartment-marker-'+aparment.id+'">'+aparment.name+'</div>'))
              .setHTML('<div id="' + apartment.id + '">' + apartment.name + '</div>');
          // popup.on('open', chooseObject(aparment.id));
          popup.on('open', function (ev) {

            console.log('popup was opened')
            lat.value = ev.target.getLngLat().lat;
            lon.value = ev.target.getLngLat().lng;
            addressFromMap.value = apartment.address;
            choosedApartmentId.value = apartment.id;
          });
          const appMarker = new mapboxgl.Marker({draggable: false}).setLngLat([apartment.longitude, apartment.latitude])
              .setPopup(popup)
              .addTo(map);
          //   const MyNewPopup = defineComponent({
          //     extends: MapContent,
          //     setup() {
          //       const myTitle = aparment.name
          //       const ticketId = aparment.id
          //       return { myTitle, ticketId };
          //     },
          //   });
          // nextTick(() => {
          //   // createApp(MyNewPopup).mount("#apartment-marker-"+aparment.id);
          //   createApp(MyNewPopup).mount("#"+aparment.id);
          // });
          // appMarker.on('dblclick', chooseObject(aparment.id));
        })

        resources.value.forEach((worker) => {

          const popup = new mapboxgl.Popup({offset: 25}).setHTML('<div id="worker-' + worker.id + '">' + worker.name + '<br><hr>' + worker.sleepingPlace + '<br>500 min</div>');
          const coordinates = (worker.sleepingPoint !== undefined) ? worker.sleepingPoint.coordinates : [14.2873, 45.3148];
          const workerMarker = new mapboxgl.Marker({
            draggable: false,
            color: `rgb(255, 0, 0)`
          }).setLngLat(coordinates).setPopup(popup).addTo(map);
        })
      });
      // const markerNew = new mapboxgl.Marker({draggable: true})
      //     .setLngLat([14.29,45.91])
      //     .addTo(map);
      // markerNew.on('dragend', onDragEnd);
      // Here we want to setup the dropdown
    });
    // const onChangeAddress = () =>
    // {
    //   map.flyTo({center: [lon.value, lat.value], zoom: 15})
    //   const markerNew = new mapboxgl.Marker({draggable: true})
    //       .setLngLat([lon.value, lat.value])
    //       .addTo(map);
    //   markerNew.on('dragend', onDragEnd);
    // };
    return {
      store,
      workers,
      departments,
      statuses,
      user,
      priorities,
      topics,
      apartments,
      teams,
      searchAdressOnMap,
      lon,
      lat,
      addressFromMap,
      choosedApartmentId,
      resources,
      openModal,
      closeModal,
      modalOpened,
      lastAddedApartment,
      handleFileUploads, ...toRefs(form)
    }
  },
  // watch() {
  //   lastAddedApartment.value
  // }
  // ,
  components: {Multiselect, flatPickr, AddObject},
  data() {
    return {

      // id:"",
      strId: "",
      title: "",
      description: "",
      teamId: [],
      body: "",
      dateRange: "",
      helpTopicId: "",
      number: "",
      topic: "",
      status: "",
      statusId: "",
      team: [],
      department: "",
      departmentId: "",
      source: "",
      priority: "",
      priorityColor: "",
      date: "",
      author: "",
      assignedTo: [],
      internalTickets: "",
      created: "",
      updated: "",
      dueDate: Math.floor((new Date()).getTime() / 1000) + 24 * 3600,
      threads: "",
      parent: "",
      apartmentId: "",
      apartmentName: "",
      arrivalRentId: "",
      departureRentId: "",
      doubleBedSet: "",
      singleBedSet: "",
      kidBedSet: "",
      startDate: Math.floor((new Date()).getTime() / 1000) + 24 * 3600,
      towellSets: "",
      bookingEntity: "",
      gifts: "",
      bedsSet: "",
      price: "",
      sellPrice: "",
      startPerformanceDate: "",
      passcodes: "",
      duration: "",
      isOnPause: "",
      highLight: "",
      listKeys: "",
      toUserUp: "",
      toUserDown: "",
      longitude: "",
      latitude: "",
      address: "",

      invoices: "",
      sla: "",
      countOfWorkers: "",
      responsibleUser: '',
      timePerWorker: "",
      forWhome: "owner",
      estimatedTime: "",
      major: "",
      coWorkers: "",
      deptId: "",
      priorityId: [],
      addExternalCustomerForm: "display: none;",
      addMapsOptions: "display: block;",
      addAdvancedOptions: "display: none;",
      myClass: "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/03_-_Captain_America_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png",
      mySecondClass: "https://cdn2.iconfinder.com/data/icons/avengers-filled/48/02_-_IRONMAN_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png",
      isMapActive: false,
      isAdvancedActive: false,
      detailExpandIcon: "f0d8",
      backcolor: "background-color: green",
      customerName: "",
      customerOIB: "",
      customerAddress: "",
      scene: {
        accessToken: 'pk.eyJ1IjoiaG9zdGEiLCJhIjoiY2pzYzAzYTVzMGc2cDQzbXV0YnVvdms2NSJ9.8_IUy4eQ7Ctcl3ImkHvmZw',
        lng: 14.2873,
        lat: 45.3148,
        zoom: 13,
        pitch: 20,
        bearing: 0
      },
      config: {
        enableTime: true,
        mode: "range",
        dateFormat: "d.m.Y H:i",
        time24hr: true,
        minTime: "8:00",
        maxTime: "22:00",
        wrap: true, // set wrap to true only when using 'input-group'
        // altFormat: 'd.m.Y',
        // altInput: false,
        // locale: "ru-RU", // locale for this instance only
      },
      openNewObject: false
    }
  },
  // computed: {
  //   lastObject () {
  //     return store.getters.lastAddedApartment;
  // },
  // watch() {
  //   lastObject () {
  //     this.apartment.id = lastAddedApartment.id;
  //   }
  // },


  methods: {
    // ...mapActions(['addTicket']),
    addApartment() {
      console.log('addApartment')
    },
    setDates(selectedDates) {
      //"24.02.2021 12:00 to 26.02.2021 12:00"
      console.log(selectedDates.target.value);
      const startDate = selectedDates.target.value.slice(0, selectedDates.target.value.indexOf(" to "));
      const untilDate = selectedDates.target.value.slice(selectedDates.target.value.indexOf(" to ") + 4, selectedDates.target.value.length);
      if (startDate && untilDate) {
        const dt1 = parseInt(startDate.substring(0, 2));
        const mon1 = parseInt(startDate.substring(3, 5));
        const yr1 = parseInt(startDate.substring(6, 10));
        const hours = parseInt(startDate.substring(12, 14));
        const minute = parseInt(startDate.substring(15, 17));
        const from = new Date(yr1, mon1 - 1, dt1, hours, minute, 0);
        const dt2 = parseInt(untilDate.substring(0, 2));
        const mon2 = parseInt(untilDate.substring(3, 5));
        const yr2 = parseInt(untilDate.substring(6, 10));
        const hours2 = parseInt(untilDate.substring(12, 14));
        const minute2 = parseInt(untilDate.substring(15, 17));
        const to = new Date(yr2, mon2 - 1, dt2, hours2, minute2, 0);
        this.startDate = Math.floor(from.getTime() / 1000);
        this.dueDate = Math.floor(to.getTime() / 1000);
      }
    },
    setLongitude() {

      console.log(this)
    },
    setLatitude() {

      console.log(this)
    },
    setAddress() {

      console.log(this)
    },
    openMapsOptions() {

      if (this.addMapsOptions === "display: block") {
        this.addMapsOptions = "display: none"
        this.isMapActive = false
      } else {
        this.addMapsOptions = "display: block"
        this.isMapActive = true
      }
    },
    openAdvancedOptions() {
      if (this.addAdvancedOptions === "display: block") {
        this.addAdvancedOptions = "display: none"
        this.isAdvancedActive = false
      } else {
        // this.detailExpandIcon = 'far fa-caret-square-up fa-lg'
        // this.detailExpandIcon = "far fa-caret-square-up fa-lg"
        this.isAdvancedActive = true
        this.addAdvancedOptions = "display: block"
      }
    },
    addExternalCustomer() {
      if (this.forWhome === 'external')
        this.addExternalCustomerForm = "display: block;"
      else {
        this.customerName = ""
        this.customerOIB = ""
        this.customerAddress = ""
        this.addExternalCustomerForm = "display: none;"
      }
    },
    addCustomer() {
      this.addExternalCustomerForm = "display: none;"
      console.log('addCustomer')
    },
    addTicket() {
      const startDate = this.dateRange.slice(0, this.dateRange.indexOf(" to "));
      const untilDate = this.dateRange.slice(this.dateRange.indexOf(" to ") + 4, this.dateRange.length);
      this.store.dispatch(ActionTypes.addTicket, {
        user_id: localStorage.getItem("USER_ID"),
        dept_id: this.departmentId,
        apartment_id: this.apartmentId,
        account_id: localStorage.getItem("ACCOUNT_ID"),
        team_id: this.teamId,
        priority_id: this.priorityId,
        sla: this.slaId,
        help_topic_id: this.helpTopicId,
        status_id: this.statusId,
        assigned_to: this.assignedTo,
        duedate: this.dueDate,
        startdate: this.startDate,
        date: Math.floor((new Date()).getTime() / 1000),
        apartmentID: this.apartmentId,
        startTime: this.startTime,
        day: this.day,
        dateRange: (untilDate && startDate) ? startDate + ' - ' + untilDate : '',
        price: this.price,
        sell_price: this.sellPrice,
        estimated_time: this.estimatedTime,
        highLight: this.highLight,
        responsible_user: this.responsibleUser,
        for_whome: this.forWhome,
        address: this.addressFromMap,
        longitude: this.longitude,//lon.value,
        latitude: this.latitude,//.value,
        body: this.body,
        title: this.title,
        threads: {
          body: this.body,
          title: this.title,
        }
      });
      router.push('tickets');
    },
    searchAdress() {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + this.address + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      axios.get(url).then((response) => {
        if (response.status === 200) {
          const lon = response.data.results[0].geometry.location.lng;
          const lat = response.data.results[0].geometry.location.lat;
          const formattedAddress = response.data.results[0].formatted_address;
          this.latitude = lat;
          this.longitude = lon;
          this.address = formattedAddress
        }
      });
    },
    fetchAdress() {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.longitude + "," + this.latitude + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      console.log(url)
      axios.get(url).then((response) => {
        if (response.status === 200) {
          const lon = response.data.results[0].geometry.location.lng;
          const lat = response.data.results[0].geometry.location.lat;
          const formattedAddress = response.data.results[0].formatted_address;
          this.latitude = lat;
          this.longitude = lon;
          this.address = formattedAddress
        }
      });
    },
    fetchPrices() {
      console.log("fetch")
      if (this.apartmentId !== "" && this.helpTopicId !== "") {
        // if (choosedApartmentId.value !=="" && this.helpTopicId !=="") {
        // ticketsService.getSettings(this.apartmentId, this.helpTopicId).then((response) => {
        // ticketsService.getSettings(choosedApartmentId.value, this.helpTopicId).then((response) => {
        ticketsService.getSettings(this.apartmentId, this.helpTopicId).then((response) => {
          if (response.data.success) {
            this.price = response.data.price;
            this.departmentId = response.data.dept_id;
            this.priorityId = response.data.priority_id;
            this.statusId = response.data.status_id;
            this.slaId = response.data.sla_id;
            this.title = response.data.title;
            this.description = response.data.description;
            this.body = response.data.description;
            while (this.teamId.length) {
              this.teamId.pop();
            }
            this.teamId.push(response.data.team_id);
            while (this.assignedTo.length) {
              this.assignedTo.pop();
            }
            debugger
            response.data.assigned_to.forEach((element) => {
              this.assignedTo.push(element);
            })
            this.responsibleUser = response.data.responsible_user;
            this.sellPrice = response.data.sell_price;
            this.estimatedTime = response.data.estimated_time;
          } else {
            this.price = 0;
            this.departmentId = "";
            this.priorityId = "";
            this.statusId = "";
            this.slaId = "";
            while (this.assignedTo.length) {
              this.assignedTo.pop();
            }
            while (this.teamId.length) {
              this.teamId.pop();
            }
            this.responsibleUser = "";
            this.sellPrice = 0;
            this.estimatedTime = 0;
          }
        })
      }
    },
  }
}

</script>

<style scoped>
.map-container {
  position: relative;
  color: #404040;
  font: 400 15px/22px 'Source Sans Pro', 'Helvetica Neue', Sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

#map {
  position: relative;
  /*left:25%;*/
  width: 100%;
  top: 0;
  bottom: 0;
  min-height: 300px;
  height: 300px;
}

.map {
  position: relative;
  /*left:25%;*/
  width: 100%;
  top: 0;
  bottom: 0;
  min-height: 300px;
  height: 300px;
}

.coordinates {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  position: absolute;
  bottom: 40px;
  left: 10px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  display: none;
}

#info {
  display: block;
  position: relative;
  margin: 0px auto;
  width: 50%;
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  color: #222;
  background: #fff;
}
</style>

<style>
.myClass {
  background-image: url("https://cdn2.iconfinder.com/data/icons/avengers-filled/48/03_-_Captain_America_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png");
}

.mySecondClass {
  background-image: url("https://cdn2.iconfinder.com/data/icons/avengers-filled/48/02_-_IRONMAN_-_infinity_war_-_end_game_-_marvel_-_avengers_-_super_hero-512.png");
}
</style>

<template>
  <div class="" style=" width: 450px;">
    <div class="row text-center" style="padding-top: 15px;">
      <h3>New Object details:</h3>
      <div class="col-lg-4">
        <label class="control-label" >Name </label>
        <input type="text"  class="form-control" v-model="name">
      </div>
      <div class="col-lg-8">
        <label class="control-label" >Address</label>
        <input type="text"  class="form-control" v-model="address">
      </div>
      <div class="col-lg-4">
        <label class="control-label">Latitude</label>
        <input type="text" class="form-control" v-model="latitudeApp">
      </div>
      <div class="col-lg-4">
        <label class="control-label">Longitude</label>
        <input type="text" class="form-control" v-model="longitudeApp">
      </div>
      <div class="col-lg-4">
        <label class="control-label">Space</label>
        <input type="text" class="form-control" v-model="space">
      </div>
      <div class="col-lg-4">
        <label class="control-label">Service price</label>
        <input type="text" class="form-control" v-model="priceCleaning">
      </div>
      <div class="col-lg-4">
        <label class="control-label">Service cost</label>
        <input type="text" class="form-control" v-model="costCleaning">
      </div>
      <div class="col-lg-4">
        <label class="control-label">Service time</label>
        <input type="text" class="form-control" v-model="timeCleaning">
      </div>
    </div>
    <div class="row" style="padding-top: 20px;">
      <div class="col-lg-12 text-center">
        <div><button type="button" class="btn btn-primary" @click="addNewObject()"><i class="fas fa-plus"></i>Add new object</button></div>
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>

</style>
<template>
  <div class="container">
    <form id="add-object" @submit.prevent="addNewObject">
    <div class="modal-header"><h3>New Object details:</h3></div>
    <div class="modal-body">
      <div class="row text-center">
        <div class="col-md-8">
          <label class="control-label" >Name </label>
          <input type="text"  class="form-control" v-model="name" required>
        </div>
        <div class="col-md-4">
          <label class="control-label">Space</label>
          <input type="text" class="form-control" v-model="space">
        </div>
        <div class="col-md-6">
          <label class="control-label" >Address</label>
          <div class="input-group">
            <a class="input-group-text" title="Search address on Map" @click="searchAdressOnMap">
              <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
            </a>
            <input type="text" class="form-control" placeholder="Type address here... and click search" v-model="address" >
          </div>
        </div>
        <div class="col-md-3">
          <label class="control-label">Latitude</label>
          <input type="text" class="form-control" v-model="latitude">
        </div>
        <div class="col-md-3">
          <label class="control-label">Longitude</label>
          <input type="text" class="form-control" v-model="longitude">
        </div>
        <div class="col-md-4">
          <label class="control-label">Service price</label>
          <input type="text" class="form-control" v-model="priceCleaning">
        </div>
        <div class="col-md-4">
          <label class="control-label">Service cost</label>
          <input type="text" class="form-control" v-model="costCleaning">
        </div>
        <div class="col-md-4">
          <label class="control-label">Service time</label>
          <input type="text" class="form-control" v-model="timeCleaning">
        </div>
        <div class="row align-items-center">
          <div class="col-md-8">
            <label class="control-label" >Owner / Customer</label>
                        <Multiselect
                            v-model='ownerId'
                            placeholder='Select owner / customer or ADD NEW...'
                            :searchable='true'
                            label='name'
                            valueProp='id'
                            trackBy='name'
                            @select='addExternalCustomer'
                            :options='owners'
                          >
                        </Multiselect>
          </div>
          <div class="col-md-4 align-items-center" >
            <label class="control-label" style="color: transparent; display: block" data-custom-for="ticketform-estimated_time">Save customer</label>
            <button type="button" style="float: left" class="btn btn-danger" @click="addExternalCustomer" >&#10011; ADD NEW</button>
          </div>
          <p class="help-block help-block-error"></p>
        </div>

      </div>
      <form id="add-customer" @submit.prevent="addCustomer">
          <div class="customer-detail" :style="addExternalCustomerForm">
            <h5>Customer detail:</h5>
            <div class="row">
              <div class="col-md-6">
                  <label class="control-label" data-custom-for="ticketform-estimated_time">Name / Company name</label>
                  <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="customerName" required>
                <p class="help-block help-block-error"></p>
              </div>
              <div class="col-md-6">
                  <label class="control-label" data-custom-for="ticketform-estimated_time">Contact person name</label>
                  <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="contactName">
                <p class="help-block help-block-error"></p>
              </div>
              <div class="col-md-4">
                  <label class="control-label" data-custom-for="ticketform-estimated_time">Email</label>
                  <input type="email" data-custom-id="ticketform-estimated_time" class="form-control" v-model="contactEmail">
                <p class="help-block help-block-error"></p>
                </div>
              <div class="col-md-4">
                  <label class="control-label" data-custom-for="ticketform-estimated_time">Tel</label>
                  <input type="tel" data-custom-id="ticketform-estimated_time" class="form-control" v-model="contactTel">
                <p class="help-block help-block-error"></p>
                </div>
              <div class="col-md-4">
                  <label class="control-label" data-custom-for="ticketform-estimated_time">OIB</label>
                  <input type="text" data-custom-id="ticketform-estimated_time" class="form-control" v-model="customerOIB" required>
                <p class="help-block help-block-error"></p>
                </div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <label class="control-label" >Address</label>
                  <div class="input-group">
                    <a class="input-group-text" title="Search address on Map" @click="searchAdressOnMap">
                      <i class="fas fa-map-marker-alt fa-lg" aria-hidden="true"></i>
                    </a>
                    <!--                          <i class="fas fa-location-arrow"></i>-->
                    <input type="text" class="form-control" placeholder="Type address here... and click search" v-model="customerAddress" >
                  </div>
                </div>
                <div class="col-md-4 align-items-center" >
                  <label class="control-label" style="color: transparent; display: block" data-custom-for="ticketform-estimated_time">Save customer</label>
                  <input type="submit" style="float: left" class="btn btn-danger" value="&#10011; Save">
                  <button type="button" class="btn btn-danger" @click="addExternalCustomer" >&#10007; Cancel</button>
                </div>
              </div>
            </div>
          </div>
      </form>
    </div>
    <div class="modal-footer" style="display: block">
      <div class="row">
        <div class="col-md-12">
          <input type="reset" value="&#x2704; Clear form" class="btn btn-warning" style="margin-right:10px">
          <input type="submit" class="btn btn-warning" value="&#10011; Add new Object">
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import {useStore} from "@/store";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {ActionTypes} from "@/store/actions";
import axios from "axios";
import {MutationType} from "@/store/mutations";
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";
import ticketService from "@/services/tickets.service";
import ownerService from "@/services/ownerService";


export default {
  name: "MapContent",
  setup(props) {
    const store = useStore();
    const state = reactive ({
      idApp : null ,
      cityName : "" ,
      address : "" ,
      userId : null ,
      name : "" ,
      externalApartmentId : null ,
      latitude : "" ,
      longitude : "" ,
      priceGeneralCleaning : 0 ,
      costGeneralCleaning : 0 ,
      timeGeneralCleaning : 0 ,
      priceTouchUpCleaning : 0 ,
      costTouchUpCleaning : 0 ,
      timeTouchUpCleaning : 0 ,
      timeCleaning : 45 ,
      priceCleaning : 0 ,
      costCleaning : 0 ,
      costOneLinen : 0 ,
      priceOneLinen : 0,
      space : 0 ,
      ownerId : null ,
      customerId : null ,
      customerName : "" ,
      contactName : "" ,
      customerOIB : "" ,
      customerAddress : "" ,
      contactEmail : "" ,
      contactTel : "",
      addExternalCustomerForm :"display: none;"
    })
    const owners = computed(() => store.getters.owners);
    const lastAddedOwner = reactive(store.getters.lastAddedOwner);
    const lastAddedOwnerId = computed(() => (!lastAddedOwner)?null:lastAddedOwner.id);
    const addExternalCustomer = () => {

          if (state.addExternalCustomerForm === "display: none;")
            state.addExternalCustomerForm = "display: block;"
          else {
            state.addExternalCustomerForm = "display: none;"
          }
        }
    const searchAdressOnMap = () => {
      const url = "https://maps.googleapis.com/maps/api/geocode/json?address="+ state.address + "&key=AIzaSyCuVxfF41Hk2T6mwb2eR8V42PBUV7JdWL4";
      axios.get(url).then((response) => {
        if(response.status===200) {
          state.latitude = response.data.results[0].geometry.location.lat;
          state.longitude = response.data.results[0].geometry.location.lng;
          state.address = response.data.results[0].formatted_address;
        }
      });
    };

    const addNewObject = async () => {

      const {data} = await ticketService.newObject({
        // id: state.idApp,
        city_name: state.cityName,
        address: state.address,
        user_id: Number(localStorage.getItem("USER_ID")),
        name: state.name,
        //external_apartment_id: state.externalApartmentId,
        latitude: Number(state.latitude),
        longitude: Number(state.longitude),
        price_general_cleaning: Number(state.priceGeneralCleaning),
        cost_general_cleaning: Number(state.costGeneralCleaning),
        time_general_cleaning: Number(state.timeGeneralCleaning),
        price_touch_up_cleaning: Number(state.priceTouchUpCleaning),
        cost_touch_up_cleaning: Number(state.costTouchUpCleaning),
        time_touch_up_cleaning: Number(state.timeTouchUpCleaning),
        time_cleaning: Number(state.timeCleaning),
        price_cleaning: Number(state.priceCleaning),
        cost_cleaning: Number(state.costCleaning),
        cost_one_linen: Number(state.costOneLinen),
        price_one_linen: Number(state.priceOneLinen),
        space: Number(state.space),
        owner_id: state.ownerId,
      }).then((response) => {

        if(response.status===201) {
          console.log(response.data)
          // const state = response.data.
          // store.dispatch(ActionTypes.getObject, response.data.apartment)
           store.commit(MutationType.addObject, response.data)
           state.apartmentId = response.data.id
           store.commit(MutationType.changeModal, false);
        }
        else {
          //report errors
          console.log(response)
        }
      });
    }
    const addCustomer = async () => {

      // store.dispatch(ActionTypes.createOwner,{
        ownerService.createOwner({
        name: state.customerName,
        contact_name: state.contactName,
        contact_email: state.contactEmail,
        contact_tel: state.contactTel,
        OIB: state.customerOIB,
        address: state.customerAddress
      }).then((response) => {

          if(response.status===201) {
            console.log(response.data)
            // const state = response.data.
            // store.dispatch(ActionTypes.getObject, response.data.apartment)
            store.commit(MutationType.createOwner, response.data)
            state.ownerId = response.data.id
            state.addExternalCustomerForm = "display: none;"
          }
          else {
            //report errors
            console.log(response)
          }
        });
    }

    const setFields = () => {
      if (props.id !== null) {
        const apartment = store.getters.getApartmentById(props.id) //getApartmetById(Number(props.id))
        if (apartment) {
          state.idApp = apartment.id  ;// null;
          state.cityName = apartment.cityName  ;// "";
          state.address = apartment.address  ;// props.addressFromMap;
          state.userId = apartment.userId  ;// localStorage.getItem("USER_ID");
          state.name = apartment.name  ;// "";
          state.externalApartmentId = apartment.externalApartmentId  ;// null;
          state.latitude = apartment.latitude  ;// props.latitude;
          state.longitude = apartment.longitude  ;// props.longitude;
          state.priceGeneralCleaning = apartment.priceGeneralCleaning  ;// 0;
          state.costGeneralCleaning = apartment.costGeneralCleaning  ;// 0;
          state.timeGeneralCleaning = apartment.timeGeneralCleaning  ;// 0;
          state.priceTouchUpCleaning = apartment.priceTouchUpCleaning  ;// 0;
          state.costTouchUpCleaning = apartment.costTouchUpCleaning  ;// 0;
          state.timeTouchUpCleaning = apartment.timeTouchUpCleaning  ;// 0;
          state.timeCleaning = apartment.timeCleaning  ;// 45;
          state.priceCleaning = apartment.priceCleaning  ;// 0;
          state.costCleaning = apartment.costCleaning  ;// 0;
          state.costOneLinen = apartment.costOneLinen  ;// 0;
          state.priceOneLinen = apartment.priceOneLinen  ;// 0;
          state.space = apartment.space  ;// 0;
          state.ownerId = apartment.ownerId  ;// null;
          state.customerId = apartment.ownerId  ;// null;
          state.customerName = apartment.ownerId? apartment.owner.customerName:"";// "";
          state.contactName = apartment.ownerId? apartment.owner.contactName:"";// "";
          state.customerOIB = apartment.ownerId? apartment.customerOIB :"";// "";
          state.customerAddress = apartment.ownerId? apartment.owner.customerAddress :"";// "";
          state.contactEmail = apartment.ownerId? apartment.owner.contactEmail :"";// "";
          state.contactTel = apartment.ownerId? apartment.owner.contactTel :"";// "";
          state.addExternalCustomerForm =  "display: none"
        }
      }
      else {
        state.idApp = null;
        state.cityName = "";
        state.address = props.addressFromMap;
        state.userId = localStorage.getItem("USER_ID");
        state.name = "";
        state.externalApartmentId = null;
        state.latitude = props.latitude;
        state.longitude = props.longitude;
        state.priceGeneralCleaning = 0;
        state.costGeneralCleaning = 0;
        state.timeGeneralCleaning = 0;
        state.priceTouchUpCleaning = 0;
        state.costTouchUpCleaning = 0;
        state.timeTouchUpCleaning = 0;
        state.timeCleaning = 45;
        state.priceCleaning = 0;
        state.costCleaning = 0;
        state.costOneLinen = 0;
        state.priceOneLinen = 0;
        state.space = 0;
        state.ownerId = null;
        state.customerId = null;
        state.customerName = "";
        state.contactName = "";
        state.customerOIB = "";
        state.customerAddress = "";
        state.contactEmail = "";
        state.contactTel = "";
        state.addExternalCustomerForm = "display: none"
      }
    }
    onMounted(()=>{
      setFields()
    })
    return {
      addNewObject,
      // lastAddedOwner,
      lastAddedOwnerId,
      searchAdressOnMap,
      addCustomer,
      addExternalCustomer,
      owners,
      ...toRefs(state),
      ...toRefs(lastAddedOwner),
    }
  },
  components: { Multiselect },

  props() {
    return {
      id: null,
      title:"",
      latitude: "",
      longitude: "",
      addressFromMap:"",
    }
  },

}
</script>

<style scoped>
</style>
